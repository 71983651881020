import React from "react";
import "../Styles/Header.css";
import logoRiomais from "../imagens/cropped-logo-riomais.png";

function Header() {
  return (
    <header className="header">
      <div className="contentheader">
        <div className="social-media">
        <div>
          <img className="logo-riomais" src={logoRiomais} alt="logo-rio-mais" />
        </div>
          
        <a href="#"><img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-facebook.png" alt="" className="icon"></img></a>
        <a href="#"><img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-linkedin.png" alt="" className="icon"></img></a>
        <a href="#"><img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-insta.png" alt="" className="icon"></img></a>
        <a href="#"><img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-youtube.png" alt="" className="icon-yt"></img></a>

        </div>
        <div className="nav">
          <nav className="navbar">
            <div className="navbar-container"> 
              <ul className="nav-menu">
              <li className="nav-item">
              <a href="#">Imprensa</a>
              </li>
              <li className="nav-item">
              <a href="#">Canais de atendimento</a>
              </li>
              <li className="nav-item">
              <a href="#">canal de Denúncias</a>
              </li>
              <li className="nav-item">
              <a href="#">Atendimento em libras</a>
              </li>
              
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
